import { throwError } from 'rxjs';
import { arrayErrors } from './';


export function manejarError(error?: string) {
  let value = 'Hubo un error en el servicio. Por favor inténtalo mas tarde.';

  if ( typeof error === 'string' ) {
    const getGenericError = arrayErrors
    .filter(({error: genericError}) => error.toLowerCase().includes(genericError.toLowerCase()) )
    .map(({description}) => description);

    if ( !getGenericError.length ) {
      value = error;
    } else if (getGenericError.length && getGenericError[0].length) {
      value = getGenericError[0];
    }
  }


  return throwError(() => new Error(value));
}
