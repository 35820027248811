export const arrayErrors: GenericError[] = [
 {
  error: 'nullpointerexception',
  description: 'Hubo un error en el servicio. Por favor inténtalo mas tarde.'
 },
 {
  error: 'jwt incorrecto',
  description: 'Su sesión ha expirado, por favor inténtalo nuevamente.'
 }
];


interface GenericError {
  error: string;
  description: string;
}
