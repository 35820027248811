import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { VeridasResponse } from '../interfaces';

@Injectable({
  providedIn: 'root'
})
export class GlobalConstants {
    public static router: Router;
    public static ngxService: NgxUiLoaderService;
    public static veridasResponse: VeridasResponse;

    public static loadDataFormulario = function() {
        GlobalConstants.ngxService.start();
        // TODO: ENVIAR DATA A BACKEND
        GlobalConstants.router.navigate(['cliente'], { queryParams: { path: 'cliente' } });
    }
}
