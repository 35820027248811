import { PlatformLocation } from "@angular/common";


export function noBackButton(location: PlatformLocation) {
  // Verificamos si el boton "atras" del navegador fue presionado
  history.pushState(null, 'null', window.location.href);
  location.onPopState(() => {
    history.pushState(null, 'null', window.location.href);
  });
}
